.logo-main {
  margin: 0.3em 0 1em;
  img {
    width: 100%;
    display: block;
    padding-right: 10%;
    @include media-breakpoint-up(lg) {
      padding: 0;
      margin-left: -9%;
    }
  }

  @include media-breakpoint-down(md) {
    margin-left: -10px;
    margin-top: 0.5em;
    img {
      padding-right: 0;
    }
  }
}

.btn-pdf {
  display: inline-block;

  background-image: url('../img/file-pdf-regular.svg');
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  background-position: 0.3em 0.3em;
  padding-left: 2.2em;
  text-align: left;

  &:hover {
    border-color: $color-link;
    background-color: transparent;
    color: $color-link;
  }
}

.link-pdf {
  background-image: url('../img/file-pdf-regular.svg');
  background-repeat: no-repeat;
  background-size: 1.2em 1.2em;
  background-position: 0.3em 0.3em;
  padding-left: 1.5em;
}

ul.support-organizations {
  a {
    color: $color-black;
    &:hover {
      color: $color-link;
    }
  }
}

.card-panel {
  background-color: $color-blue-light;
  border-radius: 0;
  border: none;
}

.card-panel-yellow {
  background-color: $color-yellow-light;
  a {
    color: $color-black;
    text-decoration: underline;
  }
}

.theme {
  color: $color-orange;
  font-weight: bold;
  font-size: 2.3rem;
  line-height: 1.2;
  margin-left: 0.1em;
}

.copyright {
  font-size: 0.9em;
  margin-top: 1em;
}

.panel {
  position: relative;
  border-radius: .25rem;
  padding: 0.5em;
  background-color: $color-gray-light;
  margin-bottom: 1em;
}

.panel-orange {
  background-color: $color-orange;
  color: $color-white;
  a {
    color: $color-white;
  }
}

.panel-yellow {
  background-color: $color-yellow-light;
  a {
    color: $color-blue-dark;
  }
  .btn-orange {
    color: $color-white;
  }
}

.panel-purple {
  background-color: $color-purple;
  color: $color-white;
}

.panel-fucsia {
  background-color: $color-fucsia;
  color: $color-white;
  a {
    color: $color-yellow;
    font-weight: bold;

    &:hover {
      color: $color-yellow;
    }
  }

  .btn-outline {
    color: $color-white;
    border-color: $color-white;

    &:hover {
      background-color: $color-white;
      color: $color-fucsia;
    }
  }
}

.download-panel {
  padding: 0.5em 0.5em 0.5em 4em;
  &.download-panel-pdf {
   &:before {
     content: " ";
     display: block;
     position: absolute;
     width: 4em;
     height: 3em;
     left: 0.5em;
     top: 0.6em;
     background-image: url('../img/file-pdf-regular.svg');
     background-repeat: no-repeat;
     background-size: 2.3em 2.3em;
     background-position: 0.3em 0.3em;
   }
  }

  .download-panel-main {
    font-size: 1.25em;
  }

  ul.download-panel-alts {
    margin: 0;
    padding: 0;
    padding-left: 1.3em;
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      display: inline-block;
      margin-right: 2em;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.thumbnail {
  position: relative;
  .thumbnail-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

a.dl-link {
  display: inline-block;
  &:before {
    content: " ";
    display: inline-block;
    background-image: url(../img/download-solid.svg);
    background-repeat: no-repeat;
    background-size: 0.75em;
    background-position: center center;
    width: 1em;
    height: 1em;
    margin-right: 0.3em;
  }
}

a.dl-link-img {
  display: block;
  position: relative;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    background-image: url(../img/download-solid-white.svg);
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: center center;
    transition: opacity 0.3s;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.section-news {
  .news-item {
    font-size: 0.9em;

    .date {
      font-weight: bold;
      margin-bottom: 0.2em;
      color: $color-fucsia;
    }
  }
}

ul.sitemap {
  padding-left: 0;
  li {
    margin-bottom: 0.3em;
    &:before {
      display: none;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.unesco-box {
  margin-top: 2em;
}

.unesco-logo {
  font-family: Arial, sans-serif;
  position: relative;

  @include media-breakpoint-down(lg) {
    width: 200px;
    margin-right: 2em;
  }

  @include media-breakpoint-only(lg) {
    float: left;
  }

  .unesco-logo-block {
    width: 50%;
    height: auto;
    display: inline-block;
  }
  .unesco-logo-text {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.1;
    font-size: 0.8em;
    margin-top: -8.3%;
  }
}

.unesco-notice {
  line-height: 1.2;
  @include media-breakpoint-down(lg) {
    font-size: 1em;
    padding-top: 0.5em;
  }
}

.table-border-top {
  &>:not(caption)>*>* {
    border-top-width: 1px;
    border-bottom-width: 0;
  }
}
