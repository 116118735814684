.modal {
  .event-details-field {
    margin-bottom: 0.6em;
  }

  .event-details-field-label {
    @include smallHeading;
    line-height: 1.3;
    color: $color-orange-text;
  }

  .event-details-field-value {

  }

  .event-details-description {
    font-size: 1.1em;
  }

  .online-event-notice {
    font-weight: bold;
    font-size: 1.1em;
    line-height: 1.3;
    margin: 1em 0 0;
    padding-left: 1.4em;
    position: relative;

    &:before {
      content: " ";
      display: block;
      width: 1.1em;
      height: 1.1em;
      position: absolute;
      left: 0;
      top: 0.15em;
      background-image: url('../img/fa/globe-solid.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

    a {
      font-weight: normal;
      font-size: 0.85em;
      display: block;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 1em;
    }
  }
}
