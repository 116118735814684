@use "sass:color";

.featured-item {
  & > a {
    display: block;
    padding: 0.5rem;
    margin: -0.5rem;
    text-decoration: none;
    color: black;

    &:hover {
      background-color: color.scale($color-white, $lightness: -4%);
    }
  }

  .image {
    margin-bottom: 0.6rem;
    background-color: $color-gray-light;
  }

  .title {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.2;
    color: $color-orange-text;
    margin-bottom: 0.1rem;

    @include media-breakpoint-down(md) {
      font-size: 1.15rem;
    }
  }

  .body {

  }
}
