@use "sass:map";

body {
  @include regularText;

  color: $color-black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include headingFont;

  text-transform: uppercase;

  @include media-breakpoint-down(md) {
    font-size: 2rem;
  }
}

h2 {
  margin: 2em 0 0.5em;
  border-bottom: 1px solid black;
  padding-bottom: 0.3em;
  margin-bottom: 0.7em;

  @include media-breakpoint-down(md) {
    font-size: 1.6rem;
  }
}

h2.medium {
  @include smallHeading;

  border-bottom: none;
  margin-bottom: 0.5em;
  padding-bottom: 0;
  font-size: 1.5em;
  font-weight: bold;
}

h2.small {
  @include smallHeading;

  border-bottom: none;
  margin-bottom: 0.5em;
  padding-bottom: 0;
  font-size: 0.9em;
  font-weight: bold;
  color: $color-fucsia;
}

h3.small {
  font-size: 1.5em;
}

h5 {
  @include media-breakpoint-down(md) {
    font-size: 1.15rem;
  }
}

b,
strong {
  font-weight: bold;

  &.soft {
    color: $color-black-soft;
  }
}

iframe {
  max-width: 100%;
}

.pre-title,
.post-title {
  text-transform: uppercase;
}

.pre-title {
  font-size: 0.9em;
}

.post-title {
  margin-top: -0.5em;
  margin-bottom: 0.5em;
}

ul {
  padding-left: 1.4em;

  li {
    list-style: none;
    position: relative;

    &::before {
      content: "•";
      display: block;
      position: absolute;
      top: -0.3em;
      left: -0.9em;
      font-size: 1.5em;
      color: $color-gray;
    }
  }

  @for $i from 1 through 9 {
    li:nth-child(9n+#{$i}):before {
      color: nth(map.values($colors-idm), $i);
    }
  }

  @for $j from 1 through 9 {
    &.bullet-color-offset-#{$j} {
      @for $i from 1 through 9 {
        li:nth-child(9n+#{$i}):before {
          color: nth(map.values($colors-idm), ($i + $j) % 9 + 1);
        }
      }
    }
  }

  &.colored-reverse {
    @for $i from 1 through 9 {
      li:nth-child(9n+#{$i}):before {
        color: nth(map.values($colors-idm), 10 - $i);
      }
    }
  }
}

ul.spaced {
  li {
    margin-bottom: 0.6em;
  }
}

.lead {
  font-size: 1.2rem;
  font-weight: 400;

  @include media-breakpoint-down(md) {
    font-size: 1.15rem;
  }
}

.large {
  font-size: 1.4rem;
  line-height: 1.2;
  color: $color-orange-text;
  margin-bottom: 0.2em;

  @include media-breakpoint-down(md) {
    font-size: 1.15rem;
  }
}

a.large {
  text-decoration: none;

  &:hover {
    color: $color-orange-text;
    text-decoration: underline;
  }
}

.medium {
  font-size: 1.2rem;

  @include media-breakpoint-down(md) {
    font-size: 1.1rem;
  }
}

.small,
small {
  font-weight: normal;
}

.bordered {
  border: 1px solid $color-black;
  padding: 0.2em 0.4em;
}

.bordered-t {
  border-top: 3px solid #c557a0;
  padding: 0.4em 0 0;
}

.box-shaded {
  padding: 1em;
  background-color: $color-gray-light;
}

.box-yellow {
  padding: 1em;
  background-color: $color-yellow-light;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;

  &.focusable:focus {
    position: static !important;
    overflow: visible;
    clip: auto;
    width: auto;
    height: auto;
  }
}

.skipnav {
  z-index: 50;
  left: 50%;
  padding: 1px 10px 2px;
  transform: translateX(-50%);
  border-bottom-width: 0;
  border-radius: 0 0 10px 10px;
  outline: 0;
  background: $color-black;
  font-size: 0.95em;
  line-height: 1.7em;

  &.visually-hidden.focusable:focus {
    position: absolute !important;
    color: #fff;
  }
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 1em;
}

.step-number {
  font-size: 3rem;
  font-weight: bold;
  line-height: 4rem;
  background-color: $color-orange;
  color: $color-white;
  border-radius: 50%;
  display: block;
  margin: 0 auto 0.2em;
  width: 4rem;
  text-align: center;
}

.coming-soon {
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
  color: $color-fucsia;
}

ul.steps {
  padding-left: 0.8em;
  margin: 1em 0;

  li {
    padding-left: 2.8rem;
    min-height: 3rem;

    &::before {
      display: none;
    }
  }

  .step-number {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    width: 2rem;
  }
}

a.link-external {
  &::after {
    content: " ";
    display: inline-block;
    height: 1em;
    width: 1em;
    background-image: url("../img/external-link-alt-solid.svg");
    background-repeat: no-repeat;
    background-size: 0.85em;
    background-position: center;
    margin-left: 0.4em;
    vertical-align: text-bottom;
    margin-bottom: 0.05em;
    opacity: 0.75;
  }
}

.btn-orange {
  background-color: $color-orange;
  border-color: $color-orange;
  color: $color-white;

  &:hover {
    background-color: darken($color-orange, 10%);
    border-color: darken($color-orange, 10%);
    color: $color-white;
  }

  &:not(:disabled, .disabled).active,
  &:not(:disabled, .disabled):active {
    background-color: darken($color-orange, 5%);
    border-color: darken($color-orange, 5%);
  }

  &.disabled,
  &:disabled {
    background-color: lighten($color-orange, 10%);
    border-color: lighten($color-orange, 10%);
  }
}

.btn-purple {
  background-color: $color-purple;
  border-color: $color-purple;
  color: $color-white;

  &:hover {
    background-color: darken($color-purple, 10%);
    border-color: darken($color-purple, 10%);
    color: $color-white;
  }

  &:not(:disabled, .disabled).active,
  &:not(:disabled, .disabled):active {
    background-color: darken($color-purple, 5%);
    border-color: darken($color-purple, 5%);
  }

  &.disabled,
  &:disabled {
    background-color: lighten($color-purple, 10%);
    border-color: lighten($color-purple, 10%);
  }
}

.btn-green {
  background-color: $color-green;
  border-color: $color-green;
  color: $color-white;

  &:hover {
    background-color: darken($color-green, 10%);
    border-color: darken($color-green, 10%);
    color: $color-white;
  }

  &:not(:disabled, .disabled).active,
  &:not(:disabled, .disabled):active {
    background-color: darken($color-green, 5%);
    border-color: darken($color-green, 5%);
  }

  &.disabled,
  &:disabled {
    background-color: lighten($color-green, 10%);
    border-color: lighten($color-green, 10%);
  }
}

.label {
  background-color: $color-yellow-light;
  display: inline-block;
  padding: 0.1em 0.3em;
}

ul.nav,
ul.pagination {
  li {
    &::before {
      display: none;
    }
  }
}

@keyframes blinking {
  0% {
    opacity: 1;
  }

  45% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  95% {
    opacity: 0;
  }
}

.blinking {
  animation: 1.5s infinite blinking;
}

.badge-new {
  color: white;
  background-color: $color-purple;
}

.border-dark {
  border: 1px solid #999;
}

.border-polaroid {
  border: 7px solid white;
  box-shadow: 5px 5px 5px -3px rgb(0 0 0 / 40%);
}

.quote {
  margin: 0.6em 4em 0.8em 0.1em;
  border-left: 8px solid $color-purple;
  padding-left: 0.7em;
  font-style: italic;

  &.color-indigo {
    border-color: $color-indigo;
  }

  &.color-green {
    border-color: $color-green;
  }

  &.color-yellow {
    border-color: $color-yellow;
  }

  &.color-red {
    border-color: $color-red;
  }

  &.color-blue {
    border-color: $color-blue;
  }

  &.color-purple {
    border-color: $color-purple;
  }

  &.color-green {
    border-color: $color-green-light;
  }

  &.color-fucsia {
    border-color: $color-fucsia;
  }

  &.color-orange {
    border-color: $color-orange;
  }
}

.rtl {
  text-align: right;
  direction: rtl;
}
