.countdown {
  margin: 0 auto 2em;
  text-align: center;

  .countdown-item {
    display: inline-block;
    text-align: center;
    height: 5em;
    width: 5em;
    margin-right: 0.5em;
    margin-bottom: 0.7em;

    &:last-child {
      margin-right: 0;
    }

    .value {
      @include headingFont;

      display: block;
      font-size: 1.75em;
      line-height: 2;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      margin: 0 auto 0.1em;
    }

    .unit {
      display: block;
      font-size: 0.9em;

      @include headingFont;

      font-weight: bold;
      text-transform: uppercase;
    }

    @include media-breakpoint-only(xs) {
      width: 3.6em;
      margin-right: 0;

      .value {
        font-size: 1.4em;
      }

      .unit {
        font-size: 0.7em;
      }
    }
  }

  .countdown-item-months .value { background-color: $color-blue; }
  .countdown-item-days .value { background-color: $color-orange; }
  .countdown-item-hours .value { background-color: $color-fucsia; }
  .countdown-item-minutes .value { background-color: $color-green-light; }
  .countdown-item-seconds .value { background-color: $color-yellow; }
}

.countdown-message {
  opacity: 0;

  @include headingFont;

  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4em;
  margin-top: -1.5em;
  margin-bottom: 0;
  transition: opacity 1s, margin 0.5s;

  &.show {
    opacity: 1;
    margin-bottom: 1em;
  }
}

.event-countdown {
  display: inline-block;
  padding: 0.25rem 0.6rem;
  margin-left: 0.2rem;
  border: 1px solid $color-yellow-light;
  border-radius: 1.5em;
  font-size: 0.85rem;
  line-height: 1.5;
  vertical-align: baseline;
  background-color: $color-yellow-light;
}
