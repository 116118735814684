ul.files {
  li {
    padding: 0.5em 0.4em;

    &:before {
      content: " ";
      background-image: url(../img/favicon.png);
      background-repeat: no-repeat;
      background-size: 0.75em;
      background-position: center center;
      width: 1em;
      height: 1em;
      margin-top: 0.6em;
    }

    a {
      color: $color-black;
      font-weight: bold;
      text-decoration: none;
      &:hover {
        color: $color-black;
        text-decoration: underline;
      }
    }
  }

  &.files-large {

  }
}

ul.files-pdf {
  li:before {
    background-image: url(../img/file-pdf-regular.svg);
  }

  ul.files-alt {
    padding: 0;
    margin-top: 0.2em;
    padding-left: 1em;
    li {
      display: inline-block;
      font-size: 0.9em;
      padding: 0;
      margin-right: 2em;
      &:before {
        background-image: none;
        content: "•";
        margin-top: 0;
        left: -0.8em;
      }
    }
  }
}

a.download {
  display: block;
  &:before {
    content: " ";
    display: inline-block;
    background-image: url('../img/fa/download-solid.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.6em;
    vertical-align: text-bottom;
  }
}
