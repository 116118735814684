ul.social {
  margin: 0;
  padding: 0;
  margin-bottom: 1em;
  text-align: right;

  @include media-breakpoint-down(md) {
    text-align: left;
    margin-left: -0.25em;
  }
  li {
    display: inline-block;
    list-style: none;
    vertical-align: top;
    padding: 0;
    margin: 0;
    margin-left: 0.75em;

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-right: 0.75em;
    }
    &:before {
      display: none;
    }
    a {
      display: block;
      width: 2rem;
      height: 2rem;
      background-size: 1.5rem 1.5rem;
      background-position: center;
      background-repeat: no-repeat;
      font-size: 0;
      line-height: 1;
    }
  }
}

.social-facebook {
  background-image: url('../img/fa/facebook-f-brands-white.svg');
}

.social-instagram {
  background-image: url('../img/fa/instagram-brands-white.svg');
}

.social-twitter {
  background-image: url('../img/fa/twitter-brands-white.svg');
}

.social-youtube {
  background-image: url('../img/fa/youtube-brands-white.svg');
}

.icon-youtube {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background-size: 1.5em 1.5em;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../img/fa/youtube-brands.svg');
  vertical-align: bottom;
}
