.bootstrap-select.tz-selector {
  min-width: 200px;
}

.form-inline .bootstrap-select {
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }

  & > .dropdown-toggle {
    background-color: $color-gray-light;
    border-color: $color-gray-light;
  }
}

.schedule {
  display: none;

  &.ready {
    display: block;
  }

  .table {
    margin-bottom: 2rem;
  }

  tr.break {
    background: repeating-linear-gradient(302deg, #fff, #fff 5px, #e4e4e4 5px, #e4e4e4 6px);
  }

  td.col-time {
    width: 120px;
    @include media-breakpoint-only(xs) {
      width: 100px;
    }
  }

  h3 {
    margin-bottom: 0.6em;
  }

  .time {
    font-weight: bold;
  }

  .timezone {
    font-size: 0.85em;
    margin-top: 0.1em;
  }

  .medium {
    font-size: 1.2em;
  }
}
