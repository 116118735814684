.banner {
  position: relative;
  border: 1px solid $color-gray;
  padding: 0.75em;

  @include headingFont;

  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.3;
  background-color: white;

  &.banner-bg-dots {
    background-image: url("../img/banner-bg-dots.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;

    @include media-breakpoint-down(lg) {
      background-image: none;
    }
  }

  &.banner-bg-spiral {
    background-image: url("../img/banner-bg-spiral.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;

    @include media-breakpoint-down(lg) {
      background-image: none;
    }
  }

  .banner-title {
    font-size: 1.8em;
    line-height: 1;
    margin: 0.05em 0;

    @include media-breakpoint-down(md) {
      margin: 0.25em 0;
    }
  }

  .banner-pre-title {
  }

  &.with-bg-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top right;

    @include media-breakpoint-down(lg) {
      background-image: none !important;
    }
  }

  .btn {
    font-weight: bold;
    font-size: 1em;
    padding: 0.2em 0.5em;
    margin-top: 0.5em;
  }
}
