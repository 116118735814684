.poster-challenge-details {
  .location {
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  a.dl-link-img {
    &:after {
      background-color: transparent;
      background-image: none;
    }
  }
}
