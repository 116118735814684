@use "sass:color";

.navbar {
  position: relative;
  margin-bottom: 3em;
  padding-bottom: 13px;

  &::after {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: 0;
    left: 0;
    content: " ";
    background-image: url("../img/dot-bg.png");
    background-size: 8px 5px;
    opacity: 0.75;
  }

  .navbar-brand {
    margin-right: 2.2em;
    padding-top: 0;
    margin-left: -5px;

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }

    .navbar-logo {
      width: 300px;
      height: 110px;
      padding-bottom: 0.5em;

      @include media-breakpoint-down(md) {
        width: 230px;
        height: 85px;
        padding-bottom: 0;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .navbar-toggler {
    border: none;
    margin-top: 1.5em;

    .navbar-toggler-icon {
      background-image: url("../img/bars-solid.svg");
    }
  }

  ul.navbar-nav {
    margin-top: 3em;

    li {
      margin: 0 1.5em 0 0;

      &::before {
        display: none;
      }

      a.nav-link {
        @include headingFont;

        font-weight: bold;
        color: black;
        text-transform: uppercase;

        &:hover {
          color: $color-orange-text;
        }

        &.featured {
          color: $color-orange-text;
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    ul.navbar-nav {
      margin-top: 1em;
    }
  }
}

.page-index {
  .navbar {
    background-color: $color-gray-light;
    padding: 0;
    margin-bottom: 2em;

    @include media-breakpoint-down(lg) {
      background-color: transparent;
      border-bottom: 2px solid black;
      margin-top: -2.5em;
    }

    &::after {
      display: none;
    }

    .navbar-nav {
      margin: 0 auto;

      @include media-breakpoint-down(lg) {
        border-top: 3px solid white;
      }

      .nav-item {
        margin: 0 2em;

        .nav-link {
          padding: 0.75rem 0.5rem;

          &.featured {
            color: $color-orange-text;
          }
        }
      }
    }

    .navbar-toggler {
      margin-left: auto;
      margin-top: 0;
      padding: 0.5em 1em;

      &:focus {
        outline: none;
        box-shadow: none;
        background-color: color.scale($color-gray-light, $lightness: -10%);
      }
    }
  }

  .navbar-brand {
    display: none;
  }
}
