@charset "UTF-8";
/*************************************************/
/* This file was automatically generated by sass */
/* Don't edit it directly                        */
/*************************************************/
.color-indigo {
  color: #0066b3;
}

.color-green {
  color: #00a651;
}

.color-yellow {
  color: #ffcb05;
}

.color-red {
  color: #ed1c24;
}

.color-blue {
  color: #00aeef;
}

.color-purple {
  color: #7670b3;
}

.color-green-light {
  color: #a6ce39;
}

.color-fucsia {
  color: #c657a0;
}

.color-orange {
  color: #f58220;
}

.orange {
  color: #e26d08;
}

a.orange {
  color: #e26d08;
}
a.orange:hover {
  color: #e26d08;
}

.fucsia {
  color: #c657a0;
}

body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
h1 b, h1 strong,
h2 b,
h2 strong,
h3 b,
h3 strong,
h4 b,
h4 strong,
h5 b,
h5 strong,
h6 b,
h6 strong {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 2rem;
  }
}

h2 {
  margin: 2em 0 0.5em;
  border-bottom: 1px solid black;
  padding-bottom: 0.3em;
  margin-bottom: 0.7em;
}
@media (max-width: 767.98px) {
  h2 {
    font-size: 1.6rem;
  }
}

h2.medium {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: none;
  margin-bottom: 0.5em;
  padding-bottom: 0;
  font-size: 1.5em;
  font-weight: bold;
}

h2.small {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: none;
  margin-bottom: 0.5em;
  padding-bottom: 0;
  font-size: 0.9em;
  font-weight: bold;
  color: #c657a0;
}

h3.small {
  font-size: 1.5em;
}

@media (max-width: 767.98px) {
  h5 {
    font-size: 1.15rem;
  }
}

b,
strong {
  font-weight: bold;
}
b.soft,
strong.soft {
  color: #333;
}

iframe {
  max-width: 100%;
}

.pre-title,
.post-title {
  text-transform: uppercase;
}

.pre-title {
  font-size: 0.9em;
}

.post-title {
  margin-top: -0.5em;
  margin-bottom: 0.5em;
}

ul {
  padding-left: 1.4em;
}
ul li {
  list-style: none;
  position: relative;
}
ul li::before {
  content: "•";
  display: block;
  position: absolute;
  top: -0.3em;
  left: -0.9em;
  font-size: 1.5em;
  color: #919191;
}
ul li:nth-child(9n+1):before {
  color: #0066b3;
}
ul li:nth-child(9n+2):before {
  color: #00a651;
}
ul li:nth-child(9n+3):before {
  color: #ffcb05;
}
ul li:nth-child(9n+4):before {
  color: #ed1c24;
}
ul li:nth-child(9n+5):before {
  color: #00aeef;
}
ul li:nth-child(9n+6):before {
  color: #7670b3;
}
ul li:nth-child(9n+7):before {
  color: #a6ce39;
}
ul li:nth-child(9n+8):before {
  color: #c657a0;
}
ul li:nth-child(9n+9):before {
  color: #f58220;
}
ul.bullet-color-offset-1 li:nth-child(9n+1):before {
  color: #ffcb05;
}
ul.bullet-color-offset-1 li:nth-child(9n+2):before {
  color: #ed1c24;
}
ul.bullet-color-offset-1 li:nth-child(9n+3):before {
  color: #00aeef;
}
ul.bullet-color-offset-1 li:nth-child(9n+4):before {
  color: #7670b3;
}
ul.bullet-color-offset-1 li:nth-child(9n+5):before {
  color: #a6ce39;
}
ul.bullet-color-offset-1 li:nth-child(9n+6):before {
  color: #c657a0;
}
ul.bullet-color-offset-1 li:nth-child(9n+7):before {
  color: #f58220;
}
ul.bullet-color-offset-1 li:nth-child(9n+8):before {
  color: #0066b3;
}
ul.bullet-color-offset-1 li:nth-child(9n+9):before {
  color: #00a651;
}
ul.bullet-color-offset-2 li:nth-child(9n+1):before {
  color: #ed1c24;
}
ul.bullet-color-offset-2 li:nth-child(9n+2):before {
  color: #00aeef;
}
ul.bullet-color-offset-2 li:nth-child(9n+3):before {
  color: #7670b3;
}
ul.bullet-color-offset-2 li:nth-child(9n+4):before {
  color: #a6ce39;
}
ul.bullet-color-offset-2 li:nth-child(9n+5):before {
  color: #c657a0;
}
ul.bullet-color-offset-2 li:nth-child(9n+6):before {
  color: #f58220;
}
ul.bullet-color-offset-2 li:nth-child(9n+7):before {
  color: #0066b3;
}
ul.bullet-color-offset-2 li:nth-child(9n+8):before {
  color: #00a651;
}
ul.bullet-color-offset-2 li:nth-child(9n+9):before {
  color: #ffcb05;
}
ul.bullet-color-offset-3 li:nth-child(9n+1):before {
  color: #00aeef;
}
ul.bullet-color-offset-3 li:nth-child(9n+2):before {
  color: #7670b3;
}
ul.bullet-color-offset-3 li:nth-child(9n+3):before {
  color: #a6ce39;
}
ul.bullet-color-offset-3 li:nth-child(9n+4):before {
  color: #c657a0;
}
ul.bullet-color-offset-3 li:nth-child(9n+5):before {
  color: #f58220;
}
ul.bullet-color-offset-3 li:nth-child(9n+6):before {
  color: #0066b3;
}
ul.bullet-color-offset-3 li:nth-child(9n+7):before {
  color: #00a651;
}
ul.bullet-color-offset-3 li:nth-child(9n+8):before {
  color: #ffcb05;
}
ul.bullet-color-offset-3 li:nth-child(9n+9):before {
  color: #ed1c24;
}
ul.bullet-color-offset-4 li:nth-child(9n+1):before {
  color: #7670b3;
}
ul.bullet-color-offset-4 li:nth-child(9n+2):before {
  color: #a6ce39;
}
ul.bullet-color-offset-4 li:nth-child(9n+3):before {
  color: #c657a0;
}
ul.bullet-color-offset-4 li:nth-child(9n+4):before {
  color: #f58220;
}
ul.bullet-color-offset-4 li:nth-child(9n+5):before {
  color: #0066b3;
}
ul.bullet-color-offset-4 li:nth-child(9n+6):before {
  color: #00a651;
}
ul.bullet-color-offset-4 li:nth-child(9n+7):before {
  color: #ffcb05;
}
ul.bullet-color-offset-4 li:nth-child(9n+8):before {
  color: #ed1c24;
}
ul.bullet-color-offset-4 li:nth-child(9n+9):before {
  color: #00aeef;
}
ul.bullet-color-offset-5 li:nth-child(9n+1):before {
  color: #a6ce39;
}
ul.bullet-color-offset-5 li:nth-child(9n+2):before {
  color: #c657a0;
}
ul.bullet-color-offset-5 li:nth-child(9n+3):before {
  color: #f58220;
}
ul.bullet-color-offset-5 li:nth-child(9n+4):before {
  color: #0066b3;
}
ul.bullet-color-offset-5 li:nth-child(9n+5):before {
  color: #00a651;
}
ul.bullet-color-offset-5 li:nth-child(9n+6):before {
  color: #ffcb05;
}
ul.bullet-color-offset-5 li:nth-child(9n+7):before {
  color: #ed1c24;
}
ul.bullet-color-offset-5 li:nth-child(9n+8):before {
  color: #00aeef;
}
ul.bullet-color-offset-5 li:nth-child(9n+9):before {
  color: #7670b3;
}
ul.bullet-color-offset-6 li:nth-child(9n+1):before {
  color: #c657a0;
}
ul.bullet-color-offset-6 li:nth-child(9n+2):before {
  color: #f58220;
}
ul.bullet-color-offset-6 li:nth-child(9n+3):before {
  color: #0066b3;
}
ul.bullet-color-offset-6 li:nth-child(9n+4):before {
  color: #00a651;
}
ul.bullet-color-offset-6 li:nth-child(9n+5):before {
  color: #ffcb05;
}
ul.bullet-color-offset-6 li:nth-child(9n+6):before {
  color: #ed1c24;
}
ul.bullet-color-offset-6 li:nth-child(9n+7):before {
  color: #00aeef;
}
ul.bullet-color-offset-6 li:nth-child(9n+8):before {
  color: #7670b3;
}
ul.bullet-color-offset-6 li:nth-child(9n+9):before {
  color: #a6ce39;
}
ul.bullet-color-offset-7 li:nth-child(9n+1):before {
  color: #f58220;
}
ul.bullet-color-offset-7 li:nth-child(9n+2):before {
  color: #0066b3;
}
ul.bullet-color-offset-7 li:nth-child(9n+3):before {
  color: #00a651;
}
ul.bullet-color-offset-7 li:nth-child(9n+4):before {
  color: #ffcb05;
}
ul.bullet-color-offset-7 li:nth-child(9n+5):before {
  color: #ed1c24;
}
ul.bullet-color-offset-7 li:nth-child(9n+6):before {
  color: #00aeef;
}
ul.bullet-color-offset-7 li:nth-child(9n+7):before {
  color: #7670b3;
}
ul.bullet-color-offset-7 li:nth-child(9n+8):before {
  color: #a6ce39;
}
ul.bullet-color-offset-7 li:nth-child(9n+9):before {
  color: #c657a0;
}
ul.bullet-color-offset-8 li:nth-child(9n+1):before {
  color: #0066b3;
}
ul.bullet-color-offset-8 li:nth-child(9n+2):before {
  color: #00a651;
}
ul.bullet-color-offset-8 li:nth-child(9n+3):before {
  color: #ffcb05;
}
ul.bullet-color-offset-8 li:nth-child(9n+4):before {
  color: #ed1c24;
}
ul.bullet-color-offset-8 li:nth-child(9n+5):before {
  color: #00aeef;
}
ul.bullet-color-offset-8 li:nth-child(9n+6):before {
  color: #7670b3;
}
ul.bullet-color-offset-8 li:nth-child(9n+7):before {
  color: #a6ce39;
}
ul.bullet-color-offset-8 li:nth-child(9n+8):before {
  color: #c657a0;
}
ul.bullet-color-offset-8 li:nth-child(9n+9):before {
  color: #f58220;
}
ul.bullet-color-offset-9 li:nth-child(9n+1):before {
  color: #00a651;
}
ul.bullet-color-offset-9 li:nth-child(9n+2):before {
  color: #ffcb05;
}
ul.bullet-color-offset-9 li:nth-child(9n+3):before {
  color: #ed1c24;
}
ul.bullet-color-offset-9 li:nth-child(9n+4):before {
  color: #00aeef;
}
ul.bullet-color-offset-9 li:nth-child(9n+5):before {
  color: #7670b3;
}
ul.bullet-color-offset-9 li:nth-child(9n+6):before {
  color: #a6ce39;
}
ul.bullet-color-offset-9 li:nth-child(9n+7):before {
  color: #c657a0;
}
ul.bullet-color-offset-9 li:nth-child(9n+8):before {
  color: #f58220;
}
ul.bullet-color-offset-9 li:nth-child(9n+9):before {
  color: #0066b3;
}
ul.colored-reverse li:nth-child(9n+1):before {
  color: #f58220;
}
ul.colored-reverse li:nth-child(9n+2):before {
  color: #c657a0;
}
ul.colored-reverse li:nth-child(9n+3):before {
  color: #a6ce39;
}
ul.colored-reverse li:nth-child(9n+4):before {
  color: #7670b3;
}
ul.colored-reverse li:nth-child(9n+5):before {
  color: #00aeef;
}
ul.colored-reverse li:nth-child(9n+6):before {
  color: #ed1c24;
}
ul.colored-reverse li:nth-child(9n+7):before {
  color: #ffcb05;
}
ul.colored-reverse li:nth-child(9n+8):before {
  color: #00a651;
}
ul.colored-reverse li:nth-child(9n+9):before {
  color: #0066b3;
}

ul.spaced li {
  margin-bottom: 0.6em;
}

.lead {
  font-size: 1.2rem;
  font-weight: 400;
}
@media (max-width: 767.98px) {
  .lead {
    font-size: 1.15rem;
  }
}

.large {
  font-size: 1.4rem;
  line-height: 1.2;
  color: #e26d08;
  margin-bottom: 0.2em;
}
@media (max-width: 767.98px) {
  .large {
    font-size: 1.15rem;
  }
}

a.large {
  text-decoration: none;
}
a.large:hover {
  color: #e26d08;
  text-decoration: underline;
}

.medium {
  font-size: 1.2rem;
}
@media (max-width: 767.98px) {
  .medium {
    font-size: 1.1rem;
  }
}

.small,
small {
  font-weight: normal;
}

.bordered {
  border: 1px solid #000;
  padding: 0.2em 0.4em;
}

.bordered-t {
  border-top: 3px solid #c557a0;
  padding: 0.4em 0 0;
}

.box-shaded {
  padding: 1em;
  background-color: #e9eeee;
}

.box-yellow {
  padding: 1em;
  background-color: #ffda82;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}
.visually-hidden.focusable:focus {
  position: static !important;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
}

.skipnav {
  z-index: 50;
  left: 50%;
  padding: 1px 10px 2px;
  transform: translateX(-50%);
  border-bottom-width: 0;
  border-radius: 0 0 10px 10px;
  outline: 0;
  background: #000;
  font-size: 0.95em;
  line-height: 1.7em;
}
.skipnav.visually-hidden.focusable:focus {
  position: absolute !important;
  color: #fff;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 1em;
}

.step-number {
  font-size: 3rem;
  font-weight: bold;
  line-height: 4rem;
  background-color: #f58220;
  color: #fff;
  border-radius: 50%;
  display: block;
  margin: 0 auto 0.2em;
  width: 4rem;
  text-align: center;
}

.coming-soon {
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: bold;
  color: #c657a0;
}

ul.steps {
  padding-left: 0.8em;
  margin: 1em 0;
}
ul.steps li {
  padding-left: 2.8rem;
  min-height: 3rem;
}
ul.steps li::before {
  display: none;
}
ul.steps .step-number {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.5rem;
  line-height: 2rem;
  width: 2rem;
}

a.link-external::after {
  content: " ";
  display: inline-block;
  height: 1em;
  width: 1em;
  background-image: url("../img/external-link-alt-solid.svg");
  background-repeat: no-repeat;
  background-size: 0.85em;
  background-position: center;
  margin-left: 0.4em;
  vertical-align: text-bottom;
  margin-bottom: 0.05em;
  opacity: 0.75;
}

.btn-orange {
  background-color: #f58220;
  border-color: #f58220;
  color: #fff;
}
.btn-orange:hover {
  background-color: #d8690a;
  border-color: #d8690a;
  color: #fff;
}
.btn-orange:not(:disabled, .disabled).active, .btn-orange:not(:disabled, .disabled):active {
  background-color: #f1750b;
  border-color: #f1750b;
}
.btn-orange.disabled, .btn-orange:disabled {
  background-color: #f79d51;
  border-color: #f79d51;
}

.btn-purple {
  background-color: #7670b3;
  border-color: #7670b3;
  color: #fff;
}
.btn-purple:hover {
  background-color: #5a539d;
  border-color: #5a539d;
  color: #fff;
}
.btn-purple:not(:disabled, .disabled).active, .btn-purple:not(:disabled, .disabled):active {
  background-color: #665faa;
  border-color: #665faa;
}
.btn-purple.disabled, .btn-purple:disabled {
  background-color: #9691c5;
  border-color: #9691c5;
}

.btn-green {
  background-color: #00a651;
  border-color: #00a651;
  color: #fff;
}
.btn-green:hover {
  background-color: #007338;
  border-color: #007338;
  color: #fff;
}
.btn-green:not(:disabled, .disabled).active, .btn-green:not(:disabled, .disabled):active {
  background-color: #008d45;
  border-color: #008d45;
}
.btn-green.disabled, .btn-green:disabled {
  background-color: #00d96a;
  border-color: #00d96a;
}

.label {
  background-color: #ffda82;
  display: inline-block;
  padding: 0.1em 0.3em;
}

ul.nav li::before,
ul.pagination li::before {
  display: none;
}

@keyframes blinking {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
}
.blinking {
  animation: 1.5s infinite blinking;
}

.badge-new {
  color: white;
  background-color: #7670b3;
}

.border-dark {
  border: 1px solid #999;
}

.border-polaroid {
  border: 7px solid white;
  box-shadow: 5px 5px 5px -3px rgba(0, 0, 0, 0.4);
}

.quote {
  margin: 0.6em 4em 0.8em 0.1em;
  border-left: 8px solid #7670b3;
  padding-left: 0.7em;
  font-style: italic;
}
.quote.color-indigo {
  border-color: #0066b3;
}
.quote.color-green {
  border-color: #00a651;
}
.quote.color-yellow {
  border-color: #ffcb05;
}
.quote.color-red {
  border-color: #ed1c24;
}
.quote.color-blue {
  border-color: #00aeef;
}
.quote.color-purple {
  border-color: #7670b3;
}
.quote.color-green {
  border-color: #a6ce39;
}
.quote.color-fucsia {
  border-color: #c657a0;
}
.quote.color-orange {
  border-color: #f58220;
}

.rtl {
  text-align: right;
  direction: rtl;
}

.reference-list {
  list-style: none;
  padding: 0;
}
.reference-list .reference-list-item {
  margin-bottom: 0.4em;
}
.reference-list .reference-list-item::before {
  display: none;
}
.reference-list .reference-list-item:last-child {
  margin-bottom: 0;
}

.arabic {
  text-align: right;
  direction: rtl;
  font-family: Arial, sans-serif;
}
.arabic h1,
.arabic h2,
.arabic h3,
.arabic h4,
.arabic h5,
.arabic h6,
.arabic .impact {
  font-family: Tahoma, Arial, sans-serif;
}
.arabic h1 strong,
.arabic h2 strong,
.arabic h3 strong,
.arabic h4 strong,
.arabic h5 strong,
.arabic h6 strong,
.arabic .impact strong {
  font-family: Tahoma, Arial, sans-serif;
}
.arabic .btn {
  font-family: Tahoma, Arial, sans-serif;
}
.arabic ul {
  padding-left: 0;
  padding-right: 1.4em;
}
.arabic ul li::before {
  left: auto;
  right: -0.9em;
}
.arabic ul.nav {
  padding-right: 0;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  vertical-align: middle;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #f58220;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.lds-ellipsis-btn-lg {
  height: 48px;
}
.lds-ellipsis-btn-lg div {
  top: 18px;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  background-color: #f58220;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  border-bottom: none;
}
.modal-header .btn-close {
  opacity: 0.75;
}
.modal-header .btn-close:hover {
  opacity: 1;
}

.modal-body .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.arabic div[data-service] .cll .c-l-b::before {
  margin-left: 0.75em;
  margin-right: 0;
}
.arabic div[data-service] .cll .c-n-a button + button {
  margin-right: 0.8em;
  margin-left: 0;
}

.section {
  position: relative;
}

.section-photos {
  padding-top: 1.5em;
}
.section-photos img {
  margin-bottom: 1.5em;
}

.section-subscribe {
  background-color: #ffda82;
  padding: 1.5em 0;
  margin: 1em 0 2em 0;
}

.section-header {
  border-bottom: 7px solid #7670b3;
  padding: 0.3em 0 0.6em 0;
  margin-bottom: 2em;
}
.section-header h1.logo-internal {
  width: 450px;
  max-width: 100%;
}

.section-yellow {
  background-color: #ffda82;
  padding: 1.5em 0;
  margin: 1em 0 2em 0;
}
.section-yellow a {
  color: #007bff;
  font-weight: bold;
}
.section-yellow a.btn {
  color: #fff;
  font-weight: normal;
}

.section-gray {
  background-color: #e9eeee;
  padding: 1.5em 0;
  margin: 1em 0 2em 0;
}

.section-sitemap {
  background-color: #7670b3;
  color: #fff;
  padding-top: 2em;
}
.section-sitemap a {
  color: #fff;
}
.section-sitemap a:hover {
  color: #fff;
}

.section-darker-blue {
  background-color: #3c6381;
  padding: 1.5em 0;
  margin: 1em 0 2em 0;
}

.section-purple {
  background-color: #7670b3;
  padding: 1.5em 0;
  margin: 1em 0 2em 0;
}

.section-banner {
  border-top: 2px solid #c657a0;
  border-bottom: 2px solid #c657a0;
  margin: 2em 0;
  padding: 2em 0;
}
.section-banner a {
  display: block;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #e26d08;
  text-transform: uppercase;
  font-size: 2.2em;
  line-height: 1.1;
  margin-bottom: 0.3em;
}
.section-banner a b, .section-banner a strong {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.navbar {
  position: relative;
  margin-bottom: 3em;
  padding-bottom: 13px;
}
.navbar::after {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  bottom: 0;
  left: 0;
  content: " ";
  background-image: url("../img/dot-bg.png");
  background-size: 8px 5px;
  opacity: 0.75;
}
.navbar .navbar-brand {
  margin-right: 2.2em;
  padding-top: 0;
  margin-left: -5px;
}
@media (max-width: 767.98px) {
  .navbar .navbar-brand {
    margin-right: 0;
  }
}
.navbar .navbar-brand .navbar-logo {
  width: 300px;
  height: 110px;
  padding-bottom: 0.5em;
}
@media (max-width: 767.98px) {
  .navbar .navbar-brand .navbar-logo {
    width: 230px;
    height: 85px;
    padding-bottom: 0;
  }
}
.navbar .navbar-brand .navbar-logo img {
  height: 100%;
  width: 100%;
}
.navbar .navbar-toggler {
  border: none;
  margin-top: 1.5em;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url("../img/bars-solid.svg");
}
.navbar ul.navbar-nav {
  margin-top: 3em;
}
.navbar ul.navbar-nav li {
  margin: 0 1.5em 0 0;
}
.navbar ul.navbar-nav li::before {
  display: none;
}
.navbar ul.navbar-nav li a.nav-link {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}
.navbar ul.navbar-nav li a.nav-link b, .navbar ul.navbar-nav li a.nav-link strong {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.navbar ul.navbar-nav li a.nav-link:hover {
  color: #e26d08;
}
.navbar ul.navbar-nav li a.nav-link.featured {
  color: #e26d08;
}
@media (max-width: 1199.98px) {
  .navbar ul.navbar-nav {
    margin-top: 1em;
  }
}

.page-index .navbar {
  background-color: #e9eeee;
  padding: 0;
  margin-bottom: 2em;
}
@media (max-width: 991.98px) {
  .page-index .navbar {
    background-color: transparent;
    border-bottom: 2px solid black;
    margin-top: -2.5em;
  }
}
.page-index .navbar::after {
  display: none;
}
.page-index .navbar .navbar-nav {
  margin: 0 auto;
}
@media (max-width: 991.98px) {
  .page-index .navbar .navbar-nav {
    border-top: 3px solid white;
  }
}
.page-index .navbar .navbar-nav .nav-item {
  margin: 0 2em;
}
.page-index .navbar .navbar-nav .nav-item .nav-link {
  padding: 0.75rem 0.5rem;
}
.page-index .navbar .navbar-nav .nav-item .nav-link.featured {
  color: #e26d08;
}
.page-index .navbar .navbar-toggler {
  margin-left: auto;
  margin-top: 0;
  padding: 0.5em 1em;
}
.page-index .navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
  background-color: #ced9d9;
}
.page-index .navbar-brand {
  display: none;
}

.section-footer {
  margin-top: 4em;
  padding: 2em 0 1em;
}
.section-footer a {
  color: #000;
  text-decoration: none;
}
.section-footer a:hover {
  color: #000;
  text-decoration: underline;
}
@media (min-width: 992px) {
  .section-footer .col-logos-r {
    text-align: right;
  }
}
@media (max-width: 991.98px) {
  .section-footer .col-logos-r {
    text-align: left;
  }
}
.section-footer .footer-logo-row {
  display: block;
  height: 150px;
  margin-bottom: 1em;
  vertical-align: middle;
  position: relative;
}
.section-footer .footer-logo-row:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
@media (max-width: 767.98px) {
  .section-footer .footer-logo-row {
    height: auto;
    vertical-align: top;
  }
}
.section-footer .footer-logo {
  display: inline-block;
  vertical-align: middle;
}
.section-footer .footer-logo img {
  display: block;
}
@media (max-width: 767.98px) {
  .section-footer .footer-logo {
    vertical-align: top;
    margin-bottom: 1em;
    display: block;
  }
}
.section-footer .logo-imu {
  margin-top: 0;
}
.section-footer .logo-imu img {
  height: 140px;
  width: 140px;
}
.section-footer .logo-kts {
  margin-top: 1%;
  width: 30%;
  margin-right: 8%;
  max-width: 300px;
}
@media (max-width: 767.98px) {
  .section-footer .logo-kts {
    width: 100%;
    margin-bottom: 3em;
  }
}
.section-footer .logo-kts img {
  width: 100%;
  height: 100%;
}
.section-footer .logo-imaginary {
  margin-right: 8%;
  width: 30%;
  max-width: 223px;
}
@media (max-width: 767.98px) {
  .section-footer .logo-imaginary {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3em;
  }
}
.section-footer .logo-imaginary img {
  width: 100%;
  height: 100%;
}
.section-footer .logo-simons-foundation {
  width: 24%;
  margin-top: 1%;
  max-width: 400px;
}
@media (max-width: 767.98px) {
  .section-footer .logo-simons-foundation {
    width: 62%;
    margin-bottom: 3em;
  }
}
.section-footer .logo-simons-foundation img {
  width: 100%;
  height: 100%;
}

.countdown {
  margin: 0 auto 2em;
  text-align: center;
}
.countdown .countdown-item {
  display: inline-block;
  text-align: center;
  height: 5em;
  width: 5em;
  margin-right: 0.5em;
  margin-bottom: 0.7em;
}
.countdown .countdown-item:last-child {
  margin-right: 0;
}
.countdown .countdown-item .value {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  display: block;
  font-size: 1.75em;
  line-height: 2;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  margin: 0 auto 0.1em;
}
.countdown .countdown-item .value b, .countdown .countdown-item .value strong {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.countdown .countdown-item .unit {
  display: block;
  font-size: 0.9em;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-weight: bold;
  text-transform: uppercase;
}
.countdown .countdown-item .unit b, .countdown .countdown-item .unit strong {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
@media (max-width: 575.98px) {
  .countdown .countdown-item {
    width: 3.6em;
    margin-right: 0;
  }
  .countdown .countdown-item .value {
    font-size: 1.4em;
  }
  .countdown .countdown-item .unit {
    font-size: 0.7em;
  }
}
.countdown .countdown-item-months .value {
  background-color: #00aeef;
}
.countdown .countdown-item-days .value {
  background-color: #f58220;
}
.countdown .countdown-item-hours .value {
  background-color: #c657a0;
}
.countdown .countdown-item-minutes .value {
  background-color: #a6ce39;
}
.countdown .countdown-item-seconds .value {
  background-color: #ffcb05;
}

.countdown-message {
  opacity: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4em;
  margin-top: -1.5em;
  margin-bottom: 0;
  transition: opacity 1s, margin 0.5s;
}
.countdown-message b, .countdown-message strong {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.countdown-message.show {
  opacity: 1;
  margin-bottom: 1em;
}

.event-countdown {
  display: inline-block;
  padding: 0.25rem 0.6rem;
  margin-left: 0.2rem;
  border: 1px solid #ffda82;
  border-radius: 1.5em;
  font-size: 0.85rem;
  line-height: 1.5;
  vertical-align: baseline;
  background-color: #ffda82;
}

.banner {
  position: relative;
  border: 1px solid #919191;
  padding: 0.75em;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.3;
  background-color: white;
}
.banner b, .banner strong {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
}
.banner.banner-bg-dots {
  background-image: url("../img/banner-bg-dots.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}
@media (max-width: 991.98px) {
  .banner.banner-bg-dots {
    background-image: none;
  }
}
.banner.banner-bg-spiral {
  background-image: url("../img/banner-bg-spiral.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}
@media (max-width: 991.98px) {
  .banner.banner-bg-spiral {
    background-image: none;
  }
}
.banner .banner-title {
  font-size: 1.8em;
  line-height: 1;
  margin: 0.05em 0;
}
@media (max-width: 767.98px) {
  .banner .banner-title {
    margin: 0.25em 0;
  }
}
.banner.with-bg-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}
@media (max-width: 991.98px) {
  .banner.with-bg-image {
    background-image: none !important;
  }
}
.banner .btn {
  font-weight: bold;
  font-size: 1em;
  padding: 0.2em 0.5em;
  margin-top: 0.5em;
}

.modal .event-details-field {
  margin-bottom: 0.6em;
}
.modal .event-details-field-label {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.3;
  color: #e26d08;
}
.modal .event-details-description {
  font-size: 1.1em;
}
.modal .online-event-notice {
  font-weight: bold;
  font-size: 1.1em;
  line-height: 1.3;
  margin: 1em 0 0;
  padding-left: 1.4em;
  position: relative;
}
.modal .online-event-notice:before {
  content: " ";
  display: block;
  width: 1.1em;
  height: 1.1em;
  position: absolute;
  left: 0;
  top: 0.15em;
  background-image: url("../img/fa/globe-solid.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
.modal .online-event-notice a {
  font-weight: normal;
  font-size: 0.85em;
  display: block;
}
@media (max-width: 575.98px) {
  .modal .online-event-notice {
    margin-bottom: 1em;
  }
}

.event-map {
  height: 550px;
  background-color: #e9eeee;
}
.event-map .lds-ellipsis {
  margin-top: 200px;
}

ul.files li {
  padding: 0.5em 0.4em;
}
ul.files li:before {
  content: " ";
  background-image: url(../img/favicon.png);
  background-repeat: no-repeat;
  background-size: 0.75em;
  background-position: center center;
  width: 1em;
  height: 1em;
  margin-top: 0.6em;
}
ul.files li a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
ul.files li a:hover {
  color: #000;
  text-decoration: underline;
}
ul.files-pdf li:before {
  background-image: url(../img/file-pdf-regular.svg);
}
ul.files-pdf ul.files-alt {
  padding: 0;
  margin-top: 0.2em;
  padding-left: 1em;
}
ul.files-pdf ul.files-alt li {
  display: inline-block;
  font-size: 0.9em;
  padding: 0;
  margin-right: 2em;
}
ul.files-pdf ul.files-alt li:before {
  background-image: none;
  content: "•";
  margin-top: 0;
  left: -0.8em;
}

a.download {
  display: block;
}
a.download:before {
  content: " ";
  display: inline-block;
  background-image: url("../img/fa/download-solid.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.6em;
  vertical-align: text-bottom;
}

.logo-main {
  margin: 0.3em 0 1em;
}
.logo-main img {
  width: 100%;
  display: block;
  padding-right: 10%;
}
@media (min-width: 992px) {
  .logo-main img {
    padding: 0;
    margin-left: -9%;
  }
}
@media (max-width: 767.98px) {
  .logo-main {
    margin-left: -10px;
    margin-top: 0.5em;
  }
  .logo-main img {
    padding-right: 0;
  }
}

.btn-pdf {
  display: inline-block;
  background-image: url("../img/file-pdf-regular.svg");
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  background-position: 0.3em 0.3em;
  padding-left: 2.2em;
  text-align: left;
}
.btn-pdf:hover {
  border-color: #007bff;
  background-color: transparent;
  color: #007bff;
}

.link-pdf {
  background-image: url("../img/file-pdf-regular.svg");
  background-repeat: no-repeat;
  background-size: 1.2em 1.2em;
  background-position: 0.3em 0.3em;
  padding-left: 1.5em;
}

ul.support-organizations a {
  color: #000;
}
ul.support-organizations a:hover {
  color: #007bff;
}

.card-panel {
  background-color: #cddcec;
  border-radius: 0;
  border: none;
}

.card-panel-yellow {
  background-color: #ffda82;
}
.card-panel-yellow a {
  color: #000;
  text-decoration: underline;
}

.theme {
  color: #f58220;
  font-weight: bold;
  font-size: 2.3rem;
  line-height: 1.2;
  margin-left: 0.1em;
}

.copyright {
  font-size: 0.9em;
  margin-top: 1em;
}

.panel {
  position: relative;
  border-radius: 0.25rem;
  padding: 0.5em;
  background-color: #e9eeee;
  margin-bottom: 1em;
}

.panel-orange {
  background-color: #f58220;
  color: #fff;
}
.panel-orange a {
  color: #fff;
}

.panel-yellow {
  background-color: #ffda82;
}
.panel-yellow a {
  color: #007bff;
}
.panel-yellow .btn-orange {
  color: #fff;
}

.panel-purple {
  background-color: #7670b3;
  color: #fff;
}

.panel-fucsia {
  background-color: #c657a0;
  color: #fff;
}
.panel-fucsia a {
  color: #ffcb05;
  font-weight: bold;
}
.panel-fucsia a:hover {
  color: #ffcb05;
}
.panel-fucsia .btn-outline {
  color: #fff;
  border-color: #fff;
}
.panel-fucsia .btn-outline:hover {
  background-color: #fff;
  color: #c657a0;
}

.download-panel {
  padding: 0.5em 0.5em 0.5em 4em;
}
.download-panel.download-panel-pdf:before {
  content: " ";
  display: block;
  position: absolute;
  width: 4em;
  height: 3em;
  left: 0.5em;
  top: 0.6em;
  background-image: url("../img/file-pdf-regular.svg");
  background-repeat: no-repeat;
  background-size: 2.3em 2.3em;
  background-position: 0.3em 0.3em;
}
.download-panel .download-panel-main {
  font-size: 1.25em;
}
.download-panel ul.download-panel-alts {
  margin: 0;
  padding: 0;
  padding-left: 1.3em;
}
.download-panel ul.download-panel-alts li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 2em;
}
.download-panel ul.download-panel-alts li:last-child {
  margin-right: 0;
}

.thumbnail {
  position: relative;
}
.thumbnail .thumbnail-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

a.dl-link {
  display: inline-block;
}
a.dl-link:before {
  content: " ";
  display: inline-block;
  background-image: url(../img/download-solid.svg);
  background-repeat: no-repeat;
  background-size: 0.75em;
  background-position: center center;
  width: 1em;
  height: 1em;
  margin-right: 0.3em;
}

a.dl-link-img {
  display: block;
  position: relative;
}
a.dl-link-img:after {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  background-image: url(../img/download-solid-white.svg);
  background-repeat: no-repeat;
  background-size: 25%;
  background-position: center center;
  transition: opacity 0.3s;
}
a.dl-link-img:hover:after {
  opacity: 1;
}

.section-news .news-item {
  font-size: 0.9em;
}
.section-news .news-item .date {
  font-weight: bold;
  margin-bottom: 0.2em;
  color: #c657a0;
}

ul.sitemap {
  padding-left: 0;
}
ul.sitemap li {
  margin-bottom: 0.3em;
}
ul.sitemap li:before {
  display: none;
}
ul.sitemap li a {
  text-decoration: none;
}
ul.sitemap li a:hover {
  text-decoration: underline;
}

.unesco-box {
  margin-top: 2em;
}

.unesco-logo {
  font-family: Arial, sans-serif;
  position: relative;
}
@media (max-width: 991.98px) {
  .unesco-logo {
    width: 200px;
    margin-right: 2em;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .unesco-logo {
    float: left;
  }
}
.unesco-logo .unesco-logo-block {
  width: 50%;
  height: auto;
  display: inline-block;
}
.unesco-logo .unesco-logo-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.1;
  font-size: 0.8em;
  margin-top: -8.3%;
}

.unesco-notice {
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  .unesco-notice {
    font-size: 1em;
    padding-top: 0.5em;
  }
}

.table-border-top > :not(caption) > * > * {
  border-top-width: 1px;
  border-bottom-width: 0;
}

ul.social {
  margin: 0;
  padding: 0;
  margin-bottom: 1em;
  text-align: right;
}
@media (max-width: 767.98px) {
  ul.social {
    text-align: left;
    margin-left: -0.25em;
  }
}
ul.social li {
  display: inline-block;
  list-style: none;
  vertical-align: top;
  padding: 0;
  margin: 0;
  margin-left: 0.75em;
}
@media (max-width: 767.98px) {
  ul.social li {
    margin-left: 0;
    margin-right: 0.75em;
  }
}
ul.social li:before {
  display: none;
}
ul.social li a {
  display: block;
  width: 2rem;
  height: 2rem;
  background-size: 1.5rem 1.5rem;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 1;
}

.social-facebook {
  background-image: url("../img/fa/facebook-f-brands-white.svg");
}

.social-instagram {
  background-image: url("../img/fa/instagram-brands-white.svg");
}

.social-twitter {
  background-image: url("../img/fa/twitter-brands-white.svg");
}

.social-youtube {
  background-image: url("../img/fa/youtube-brands-white.svg");
}

.icon-youtube {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background-size: 1.5em 1.5em;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../img/fa/youtube-brands.svg");
  vertical-align: bottom;
}

.idm-gallery {
  margin: 2em 0;
}
.idm-gallery .idm-gallery-item {
  display: block;
  margin-bottom: 1.5em;
}
.idm-gallery .idm-gallery-item img {
  display: block;
  box-shadow: 5px 5px 7px -3px rgba(0, 0, 0, 0.6);
}

.liveblog {
  margin-bottom: 3em;
}

.liveblog-entry {
  margin-bottom: 2.5em;
}
.liveblog-entry .time {
  display: inline-block;
  padding: 0.1em 0.5em;
  margin-bottom: 0.3em;
  border-radius: 0.4em;
  color: white;
  background-color: #007bff;
  font-size: 1.2em;
  font-weight: bold;
}
.liveblog-entry .date {
  display: inline-block;
  padding: 0.1em 0.5em;
  margin-bottom: 0.3em;
  color: #007bff;
  font-size: 1.2em;
}
.liveblog-entry .message {
  font-size: 1.2em;
  margin-bottom: 0.2em;
}

.liveblog-entry-poster:after,
.liveblog-entry-instagram:after,
.liveblog-entry-twitter:after {
  content: " ";
  display: block;
  background-image: url("../img/loading.gif");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin: 0.25em 0 1em;
}
.liveblog-entry-poster .data,
.liveblog-entry-instagram .data,
.liveblog-entry-twitter .data {
  display: none;
}
.liveblog-entry-poster.processed .data,
.liveblog-entry-instagram.processed .data,
.liveblog-entry-twitter.processed .data {
  display: block;
}
.liveblog-entry-poster.loaded:after,
.liveblog-entry-instagram.loaded:after,
.liveblog-entry-twitter.loaded:after {
  display: none;
}

.liveblog-entry-poster-thumbnail {
  display: block;
  max-width: 250px;
  border: 1px solid #919191;
  position: relative;
}
.liveblog-entry-poster-thumbnail:after {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  background-image: url("../img/fa/search-plus-solid-white.svg");
  background-repeat: no-repeat;
  background-size: 25%;
  background-position: center center;
  transition: opacity 0.3s;
}
.liveblog-entry-poster-thumbnail:hover:after {
  opacity: 1;
}

.liveblog-entry-2022-photo-thumbnail {
  display: inline-block;
  position: relative;
}
.liveblog-entry-2022-photo-thumbnail:after {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  background-image: url("../img/fa/search-plus-solid-white.svg");
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center center;
  transition: opacity 0.3s;
}
.liveblog-entry-2022-photo-thumbnail:hover:after {
  opacity: 1;
}

.liveblog-more {
  background-color: #007bff;
  color: white;
  font-size: 1.2em;
  border: none;
  border-radius: 2em;
  padding: 0.3em 1em;
  margin: 0 auto;
  display: block;
}
.liveblog-more:focus {
  outline: none;
  text-decoration: underline;
}

.liveblog-lastfetch {
  margin-top: -1em;
  margin-bottom: 2em;
  font-size: 0.9em;
}

#main-stage {
  display: none;
  transition: max-height 1s;
  max-height: 0;
  overflow: hidden;
}
#main-stage.curtains-open {
  max-height: 1000px;
}
#main-stage.visible {
  display: block;
}

.featured-item > a {
  display: block;
  padding: 0.5rem;
  margin: -0.5rem;
  text-decoration: none;
  color: black;
}
.featured-item > a:hover {
  background-color: whitesmoke;
}
.featured-item .image {
  margin-bottom: 0.6rem;
  background-color: #e9eeee;
}
.featured-item .title {
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.2;
  color: #e26d08;
  margin-bottom: 0.1rem;
}
@media (max-width: 767.98px) {
  .featured-item .title {
    font-size: 1.15rem;
  }
}
.impact {
  font-size: 1.6em;
  line-height: 1.2;
}
.impact.smaller {
  font-size: 1.2em;
}
.impact.huge {
  font-size: 2.7em;
}

.creative-challenge-browser .idm-gallery .idm-gallery-item {
  display: inline-block;
  border: 1px solid #929292;
  box-shadow: 3px 3px 9px -2px rgba(0, 0, 0, 0.7);
  overflow: hidden;
  border-radius: 3%;
}
.creative-challenge-browser .idm-gallery .idm-gallery-item img {
  box-shadow: none;
}

.creative-challenge-map {
  height: 600px;
}
@keyframes marker-pop {
  0% {
    transform: scale(0%);
  }
  100% {
    transform: scale(100%);
  }
}
.creative-challenge-map .photo-marker .photo {
  background-size: contain;
  border: 2px solid black;
  width: 50px;
  height: 50px;
  animation: marker-pop 0.3s;
  cursor: pointer;
}

.photo-challenge-modal .modal-header {
  padding: 0.3em 0.5em;
  background-color: #c657a0;
}
.photo-challenge-modal .modal-header .bnt-close-wrapper {
  margin-left: auto;
  vertical-align: middle;
  margin-right: 4px;
  margin-bottom: -5px;
}
@media (min-width: 1200px) {
  .photo-challenge-modal .modal-header {
    height: 0;
    padding: 0;
  }
  .photo-challenge-modal .modal-header .bnt-close-wrapper {
    background-color: #c657a0;
    border-radius: 50%;
    margin-right: -17px;
    margin-left: auto;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 40px;
    font-size: 40px;
    z-index: 1000;
  }
  .photo-challenge-modal .modal-header button.btn-close {
    width: 60%;
    height: 60%;
    background-size: contain;
    padding: 0;
    margin: -20% 0 0;
    display: inline-block;
    vertical-align: middle;
  }
  .photo-challenge-modal .modal-header button.btn-close:focus, .photo-challenge-modal .modal-header button.btn-close:focus-visible {
    outline: 1px dotted black;
    box-shadow: none;
    border-radius: 50%;
    padding: 1px;
  }
}
.photo-challenge-modal .modal-content {
  border-radius: 0.5rem;
}
.photo-challenge-modal .modal-content .modal-body {
  padding: 0 15px;
}
@media (min-width: 1200px) {
  .photo-challenge-modal .modal-content .modal-body {
    padding: 0;
  }
}
.photo-challenge-modal .modal-content .col-image {
  text-align: center;
  background-color: black;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-challenge-modal .modal-content .col-image img {
  max-height: 750px;
}
.photo-challenge-modal .modal-content .col-content {
  padding: 1em calc(1em + 15px);
}
@media (min-width: 1200px) {
  .photo-challenge-modal .modal-content .col-content {
    padding-left: 1.5em;
  }
}

.photo-challenge-details .location {
  display: inline-block;
  background-color: #ffda82;
  padding: 0.1em 0.4em;
  margin: 0.25em 0;
  font-size: 1.15em;
}
.photo-challenge-details .location .country {
  font-weight: bold;
  display: inline-block;
}

a.photo-gallery-link {
  border: 2px solid #4f4f4f;
  color: #000;
  text-decoration: none;
}
a.photo-gallery-link:hover {
  color: #000;
  text-decoration: underline;
}

.poster-challenge-details .location {
  font-size: 1.2em;
  margin-bottom: 1em;
}
.poster-challenge-details a.dl-link-img:after {
  background-color: transparent;
  background-image: none;
}

.bootstrap-select.tz-selector {
  min-width: 200px;
}

@media (max-width: 767.98px) {
  .form-inline .bootstrap-select {
    width: 100% !important;
  }
}
.form-inline .bootstrap-select > .dropdown-toggle {
  background-color: #e9eeee;
  border-color: #e9eeee;
}

.schedule {
  display: none;
}
.schedule.ready {
  display: block;
}
.schedule .table {
  margin-bottom: 2rem;
}
.schedule tr.break {
  background: repeating-linear-gradient(302deg, #fff, #fff 5px, #e4e4e4 5px, #e4e4e4 6px);
}
.schedule td.col-time {
  width: 120px;
}
@media (max-width: 575.98px) {
  .schedule td.col-time {
    width: 100px;
  }
}
.schedule h3 {
  margin-bottom: 0.6em;
}
.schedule .time {
  font-weight: bold;
}
.schedule .timezone {
  font-size: 0.85em;
  margin-top: 0.1em;
}
.schedule .medium {
  font-size: 1.2em;
}

.no-ul a, a.no-ul {
  text-decoration: none;
}
.no-ul a:hover, a.no-ul:hover {
  text-decoration: underline;
}