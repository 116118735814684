.liveblog {
  margin-bottom: 3em;
}

.liveblog-entry {
  margin-bottom: 2.5em;
  .time {
    display: inline-block;
    padding: 0.1em 0.5em;
    margin-bottom: 0.3em;
    border-radius: 0.4em;
    color: white;
    background-color: $color-blue-dark;
    font-size: 1.2em;
    font-weight: bold;
  }
  .date {
    display: inline-block;
    padding: 0.1em 0.5em;
    margin-bottom: 0.3em;
    color: $color-blue-dark;
    font-size: 1.2em;
  }

  .message {
    font-size: 1.2em;
    margin-bottom: 0.2em;
  }
}

.liveblog-entry-poster,
.liveblog-entry-instagram,
.liveblog-entry-twitter {
  &:after {
    content: " ";
    display: block;
    background-image: url('../img/loading.gif');
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    margin: 0.25em 0 1em;
  }
  .data {
    display: none;
  }
  &.processed {
    .data {
      display: block;
    }
  }
  &.loaded {
    &:after {
      display: none;
    }
  }
}

.liveblog-entry-poster-thumbnail {
  display: block;
  max-width: 250px;
  border: 1px solid $color-gray;
  position: relative;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    background-image: url('../img/fa/search-plus-solid-white.svg');
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: center center;
    transition: opacity 0.3s;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.liveblog-entry-2022-photo-thumbnail {
  display: inline-block;
  position: relative;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    background-image: url('../img/fa/search-plus-solid-white.svg');
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: center center;
    transition: opacity 0.3s;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
}


.liveblog-more {
  background-color: $color-blue-dark;
  color: white;
  font-size: 1.2em;
  border: none;
  border-radius: 2em;
  padding: 0.3em 1em;
  margin: 0 auto;
  display: block;
  &:focus {
    outline: none;
    text-decoration: underline;
  }
}

.liveblog-lastfetch {
  margin-top: -1em;
  margin-bottom: 2em;
  font-size: 0.9em;
}

#main-stage {
  display: none;
  transition: max-height 1s;
  max-height: 0;
  overflow: hidden;

  &.curtains-open {
    max-height: 1000px;
  }

  &.visible {
    display: block;
  }
}
