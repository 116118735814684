
.section-footer {
  margin-top: 4em;
  padding: 2em 0 1em;

  a {
    color: $color-black;
    text-decoration: none;
    &:hover {
      color: $color-black;
      text-decoration: underline;
    }
  }

  .col-logos-r {
    @include media-breakpoint-up(lg) {
      text-align: right;
    }

    @include media-breakpoint-down(lg) {
      text-align: left;
    }
  }

  .footer-logo-row {
    display: block;
    height: 150px;
    margin-bottom: 1em;
    vertical-align: middle;
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    @include media-breakpoint-down(md) {
      height: auto;
      vertical-align: top;
    }
  }

  .footer-logo {
    display: inline-block;
    vertical-align: middle;
    img {
      display: block;
    }

    @include media-breakpoint-down(md) {
      vertical-align: top;
      margin-bottom: 1em;
      display: block;
    }
  }

  .logo-imu {
    margin-top: 0;

    img {
      height: 140px;
      width: 140px;
    }
  }

  .logo-kts {
    margin-top: 1%;
    width: 30%;
    margin-right: 8%;
    max-width: 300px;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 3em;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .logo-imaginary {
    margin-right: 8%;
    width: 30%;
    max-width: 223px;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 3em;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .logo-simons-foundation {
    width: 24%;
    margin-top: 1%;
    max-width: 400px;

    @include media-breakpoint-down(lg) {

    }

    @include media-breakpoint-down(md) {
      width: 62%;
      margin-bottom: 3em;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
