// Patch magins for Arabic
.arabic {
  div[data-service] .cll {
    .c-l-b::before {
      margin-left: 0.75em;
      margin-right: 0;
    }

    .c-n-a button + button {
      margin-right: 0.8em;
      margin-left: 0;
    }
  }
}
