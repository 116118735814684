.arabic {
  text-align: right;
  direction: rtl;
  font-family: Arial, sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .impact {
    font-family: Tahoma, Arial, sans-serif;

    strong {
      font-family: Tahoma, Arial, sans-serif;
    }
  }

  .btn {
    font-family: Tahoma, Arial, sans-serif;
  }

  ul {
    padding-left: 0;
    padding-right: 1.4em;

    li {
      &::before {
        left: auto;
        right: -0.9em;
      }
    }
  }

  ul.nav {
    padding-right: 0;
  }
}
