.impact {
  font-size: 1.6em;
  line-height: 1.2;

  &.smaller {
    font-size: 1.2em;
  }

  &.huge {
    font-size: 2.7em;
  }
}

.creative-challenge-browser {
  .idm-gallery {
    .idm-gallery-item {
      display: inline-block;
      border: 1px solid #929292;
      box-shadow: 3px 3px 9px -2px rgb(0 0 0 / 70%);
      overflow: hidden;
      border-radius: 3%;

      img {
        box-shadow: none;
      }
    }
  }
}

.creative-challenge-map {
  height: 600px;

  @keyframes marker-pop {
    0% {
      transform: scale(0%);
    }

    100% {
      transform: scale(100%);
    }
  }

  .photo-marker .photo {
    background-size: contain;
    border: 2px solid black;
    width: 50px;
    height: 50px;
    animation: marker-pop 0.3s;
    cursor: pointer;
  }
}

.photo-challenge-modal {
  .modal-header {
    padding: 0.3em 0.5em;
    background-color: $color-fucsia;

    .bnt-close-wrapper {
      margin-left: auto;
      vertical-align: middle;
      margin-right: 4px;
      margin-bottom: -5px;
    }

    @include media-breakpoint-up(xl) {
      height: 0;
      padding: 0;

      .bnt-close-wrapper {
        background-color: $color-fucsia;
        border-radius: 50%;
        margin-right: -17px;
        margin-left: auto;
        text-align: center;
        vertical-align: middle;
        width: 40px;
        height: 40px;
        padding: 0;
        line-height: 40px;
        font-size: 40px;
        z-index: 1000;
      }

      button.btn-close {
        width: 60%;
        height: 60%;
        background-size: contain;
        padding: 0;
        margin: -20% 0 0;
        display: inline-block;
        vertical-align: middle;

        &:focus,
        &:focus-visible {
          outline: 1px dotted black;
          box-shadow: none;
          border-radius: 50%;
          padding: 1px;
        }
      }
    }
  }

  .modal-content {
    border-radius: 0.5rem;

    .modal-body {
      padding: 0 15px;

      @include media-breakpoint-up(xl) {
        padding: 0;
      }
    }

    .col-image {
      text-align: center;
      background-color: black;
      position: relative;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-height: 750px;
      }
    }

    .col-content {
      padding: 1em calc(1em + 15px);

      @include media-breakpoint-up(xl) {
        padding-left: 1.5em;
      }
    }
  }
}

.photo-challenge-details {
  .location {
    display: inline-block;
    background-color: $color-yellow-light;
    padding: 0.1em 0.4em;
    margin: 0.25em 0;
    font-size: 1.15em;

    .country {
      font-weight: bold;
      display: inline-block;
    }
  }
}

a.photo-gallery-link {
  border: 2px solid #4f4f4f;
  color: $color-black;
  text-decoration: none;

  &:hover {
    color: $color-black;
    text-decoration: underline;
  }
}
