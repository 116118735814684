.section {
  position: relative;
}

.section-photos {
  padding-top: 1.5em;
  img {
    margin-bottom: 1.5em;
  }
}

.section-subscribe {
  background-color: $color-yellow-light;
  padding: 1.5em 0;
  margin: 1em 0 2em 0;
}

.section-header {

  border-bottom: 7px solid $color-purple;
  padding: 0.3em 0 0.6em 0;
  margin-bottom: 2em;

  h1.logo-internal {
    width: 450px;
    max-width: 100%;
  }
}

.section-yellow {
  background-color: $color-yellow-light;
  padding: 1.5em 0;
  margin: 1em 0 2em 0;

  a {
    color: $color-blue-dark;
    font-weight: bold;
  }

  a.btn {
    color: $color-white;
    font-weight: normal;
  }
}

.section-gray {
  background-color: $color-gray-light;
  padding: 1.5em 0;
  margin: 1em 0 2em 0;
}

.section-sitemap {
  background-color: $color-purple;
  color: $color-white;
  padding-top: 2em;
  a {
    color: $color-white;
    &:hover {
      color: $color-white;
    }
  }
}

.section-darker-blue {
  background-color: $color-blue-darker;
  padding: 1.5em 0;
  margin: 1em 0 2em 0;
}

.section-purple {
  background-color: $color-purple;
  padding: 1.5em 0;
  margin: 1em 0 2em 0;
}

.section-banner {
  border-top: 2px solid $color-fucsia;
  border-bottom: 2px solid $color-fucsia;
  margin: 2em 0;
  padding: 2em 0;

  a {
    display: block;
    @include headingFont;
    color: $color-orange-text;
    text-transform: uppercase;
    font-size: 2.2em;
    line-height: 1.1;
    margin-bottom: 0.3em;
  }
}
