.reference-list {
  list-style: none;
  padding: 0;

  .reference-list-item {
    margin-bottom: 0.4em;

    &::before {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
