@mixin regularFont {
  @include openSansRegular;
}

@mixin headingFont {
  @include openSansRegular;
  b, strong {
    @include openSansBold;
  }
}

@mixin smallHeading {
  @include openSansBold;
  text-transform: uppercase;
}

@mixin smallText {

}

@mixin regularText {
  @include regularFont;
}

@mixin largeText {

}
