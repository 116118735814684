$color-white-real: #fff;
$color-black-real: #000;
$color-white: $color-white-real;
$color-black: $color-black-real;
$color-black-soft: #333;

@mixin neg {
  color: $color-white;
  -webkit-font-smoothing: antialiased !important;
}

$color-blue-dark: #007bff;
$color-blue-darker: #3c6381;
$color-indigo: #0066b3;
$color-green: #00a651;
$color-yellow: #ffcb05;
$color-red: #ed1c24;
$color-blue: #00aeef;
$color-purple: #7670b3;
$color-green-light: #a6ce39;
$color-fucsia: #c657a0;
$color-orange: #f58220;
$color-yellow-light: #ffda82;
$color-purple-light: #cecaf1;
$color-blue-light: #cddcec;
$color-orange-text: #e26d08;
$color-gray: #919191;
$color-gray-light: #e9eeee;
$colors-idm: (
  "indigo": $color-indigo,
  "green": $color-green,
  "yellow": $color-yellow,
  "red": $color-red,
  "blue": $color-blue,
  "purple": $color-purple,
  "green-light": $color-green-light,
  "fucsia": $color-fucsia,
  "orange": $color-orange
);
$color-1: $color-indigo;
$color-2: $color-green;
$color-3: $color-yellow;
$color-4: $color-red;
$color-5: $color-blue;
$color-6: $color-purple;
$color-7: $color-green-light;
$color-8: $color-fucsia;
$color-9: $color-orange;
$color-link: $color-blue-dark;

@each $name, $color in $colors-idm {
  .color-#{$name} {
    color: $color;
  }
}

.orange {
  color: $color-orange-text;
}

a.orange {
  color: $color-orange-text;

  &:hover {
    color: $color-orange-text;
  }
}

.fucsia {
  color: $color-fucsia;
}
