.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  background-color: $color-orange;
  color: white;
  font-weight: bold;
  font-size: 1.3em;
  border-bottom: none;

  .btn-close {
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }
  }
}

.modal-body {
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

