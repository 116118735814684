.idm-gallery {
  margin: 2em 0;

  .idm-gallery-item {
    display: block;
    margin-bottom: 1.5em;

    img {
      display: block;
      box-shadow: 5px 5px 7px -3px rgba(0, 0, 0, 0.6)
    }
  }
}


